import React from 'react';
import { useTheme } from '../context/ThemeContext';

const About = () => {
  const { isDarkMode } = useTheme();

  const sectionClass = `mb-12 p-6 rounded-2xl backdrop-blur-md shadow-lg border ${
    isDarkMode ? 'bg-gray-800/30 border-gray-700/30' : 'bg-white/30 border-gray-200/30'
  }`;

  const headingClass = `text-3xl font-medium mb-6 ${isDarkMode ? 'text-white' : 'text-gray-800'}`;
  const subHeadingClass = `text-xl font-medium mb-4 ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`;
  const textClass = `text-base leading-relaxed ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`;
  const highlightClass = `font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`;
  const dateClass = `text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`;

  return (
    <div className="max-w-5xl mx-auto px-4 py-24">
      {/* Work Experience Section */}
      <section className={sectionClass}>
        <h2 className={headingClass}>Work Experience</h2>
        
        {/* Proxisense */}
        <div className="mb-8">
          <div className="flex justify-between items-start mb-2">
            <h3 className={subHeadingClass}>R&D Engineer | Proxisense</h3>
            <span className={dateClass}>Mar 2023 – Present</span>
          </div>
          <p className={`${textClass} mb-2`}>Cody Technology Park, Farnborough/UK</p>
          <ul className="list-disc pl-6 space-y-2">
            <li className={textClass}>Leading development of skin cancer detection device from concept through clinical validation</li>
            <li className={textClass}>Developed automated testing system reducing measurement time from 2 minutes to 5 seconds</li>
            <li className={textClass}>Created and deployed desktop application integrating novel thermal sensing technology</li>
          </ul>
          <div className="mt-4 pl-6">
            <p className={`${textClass} mb-2`}><span className={highlightClass}>Stack:</span> Python, GUI development, Hardware integration</p>
            <p className={textClass}><span className={highlightClass}>Focus:</span> Medical device development, Testing automation, Data analysis</p>
          </div>
        </div>

        {/* University of Leeds - Researcher */}
        <div className="mb-8">
          <div className="flex justify-between items-start mb-2">
            <h3 className={subHeadingClass}>Postgraduate Researcher | University of Leeds</h3>
            <span className={dateClass}>Oct 2018 - Apr 2023</span>
          </div>
          <p className={`${textClass} mb-2`}>Leeds, UK</p>
          <ul className="list-disc pl-6 space-y-2">
            <li className={textClass}>Developed new testing methodology for natural hip simulation in collaboration with DePuy Synthes (J&J)</li>
            <li className={textClass}>Created custom fixtures and protocols for long-term fatigue testing of biological tissues</li>
            <li className={textClass}>Conducted advanced imaging analysis using multiphoton microscopy and Raman spectroscopy</li>
          </ul>
          <div className="mt-4 pl-6">
            <p className={`${textClass} mb-2`}><span className={highlightClass}>Key Achievement:</span> Published methodology in PLOS One, establishing new approach for pre-clinical testing</p>
            <p className={textClass}><span className={highlightClass}>Technologies:</span> Advanced microscopy, Biomechanical testing, CAD/CAM</p>
          </div>
        </div>

        {/* University of Leeds - Co-supervisor */}
        <div>
          <div className="flex justify-between items-start mb-2">
            <h3 className={subHeadingClass}>Co-supervisor & Lab Demonstrator | University of Leeds</h3>
            <span className={dateClass}>Sep 2019 - Jul 2020</span>
          </div>
          <ul className="list-disc pl-6 space-y-2">
            <li className={textClass}>Managed master's level students, leading to successful project completion and publication</li>
            <li className={textClass}>Designed training sessions for dissection and joint testing equipment</li>
            <li className={textClass}>Operated testing equipment including joint simulators and imaging systems</li>
          </ul>
        </div>
      </section>

      {/* Education Section */}
      <section className={sectionClass}>
        <h2 className={headingClass}>Education</h2>
        
        {/* PhD */}
        <div className="mb-8">
          <div className="flex justify-between items-start mb-2">
            <h3 className={subHeadingClass}>PhD in Tissue Engineering and Regenerative Medicine</h3>
            <span className={dateClass}>Oct 2018 - Apr 2023</span>
          </div>
          <p className={`${textClass} mb-2`}>University of Leeds, UK</p>
          <ul className="list-disc pl-6 space-y-2">
            <li className={textClass}><span className={highlightClass}>Thesis:</span> "Chondrolabral damage in the natural hip joint"</li>
            <li className={textClass}>Industry-funded research through DePuy Synthes focusing on orthopaedic testing</li>
            <li className={textClass}>Developed novel imaging and testing protocols for tissue damage assessment</li>
            <li className={textClass}>Part of Medical Engineering CDT program providing broader exposure to healthcare challenges</li>
          </ul>
        </div>

        {/* MEng */}
        <div>
          <div className="flex justify-between items-start mb-2">
            <h3 className={subHeadingClass}>MEng in Biomedical Engineering (First Class with Honours)</h3>
            <span className={dateClass}>Sep 2013 - Jul 2018</span>
          </div>
          <p className={`${textClass} mb-2`}>Queen Mary University of London, UK</p>
          <ul className="list-disc pl-6 space-y-2">
            <li className={textClass}>Key Project: Designed intramedullary nail from concept through FEA analysis</li>
            <li className={textClass}>Specialized modules: Implant Design, Tissue Mechanics, Medical Device Development</li>
            <li className={textClass}>Led improvements in course delivery as faculty-student representative</li>
          </ul>
        </div>
      </section>

      {/* Skills & Certifications Section */}
      <section className={sectionClass}>
        <h2 className={headingClass}>Technical Skills & Certifications</h2>
        
        <div className="grid md:grid-cols-2 gap-8">
          {/* Software & Analysis */}
          <div>
            <h3 className={subHeadingClass}>Software & Analysis</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li className={textClass}>Python: Data analysis, GUI development, Automation</li>
              <li className={textClass}>CAD: SolidWorks, Fusion 360, Inventor, AutoCAD</li>
              <li className={textClass}>Analysis: MATLAB, FEA/CFD experience</li>
            </ul>
          </div>

          {/* Laboratory Experience */}
          <div>
            <h3 className={subHeadingClass}>Laboratory Experience</h3>
            <ul className="list-disc pl-6 space-y-2">
              <li className={textClass}>Microscopy: Multiphoton, Raman spectroscopy</li>
              <li className={textClass}>Testing: Biomechanical analysis, Joint simulation</li>
              <li className={textClass}>Standards: ISO 9001 environment, SOPs, COSHH</li>
            </ul>
          </div>
        </div>

        {/* Certifications */}
        <div className="mt-8">
          <h3 className={subHeadingClass}>Certifications</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li className={textClass}>Lean Six Sigma Green Belt (2021)</li>
            <li className={textClass}>Lean Six Sigma Yellow Belt (2021)</li>
          </ul>
        </div>
      </section>

      {/* Publications Section */}
      <section className={sectionClass}>
        <h2 className={headingClass}>Publications</h2>
        <ul className="space-y-4">
          <li className={textClass}>
            Jimenez-Cruz D, Dubey M, et al. (2022) "An in vitro methodology for experimental simulation on the natural hip joint." 
            <span className={highlightClass}> PLoS One. 17(8):e0272264</span>
          </li>
          <li className={textClass}>
            Dubey M, et al. (2023) "Investigating effects of mechanical damage on the microstructure at the chondrolabral junction in the hip." 
            <span className={highlightClass}> Orthopaedic Research Society</span>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default About;
