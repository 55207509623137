import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import { blogPosts } from '../data/blogPosts';
import LogoTicker from './LogoTicker';

const Portfolio = () => {
  const [showContactButton, setShowContactButton] = useState(false);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
      setShowContactButton(scrollPosition > pageHeight * 0.5);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollClick = () => {
    const projectsSection = document.getElementById('projects');
    if (projectsSection) {
      window.scrollTo({
        top: projectsSection.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  const MainContent = () => {
    return (
      <main className="relative z-10 pt-32 pb-16 px-6">
        {/* Hero Section */}
        <section className="h-[85vh] flex flex-col justify-center items-center text-center relative -mt-20">
          {/* Background Logo */}
          <div 
            className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[600px] h-[600px] max-w-[90vw] max-h-[90vw] sm:max-w-full sm:max-h-full select-none ${isDarkMode ? 'opacity-[0.07] text-white' : 'opacity-[0.15] text-gray-600'}`}
            style={{ 
              pointerEvents: 'none',
              touchAction: 'none',
              willChange: 'transform'
            }}
            aria-hidden="true"
          >
            <svg 
              viewBox="0 0 100 100" 
              className="w-full h-full"
              style={{ 
                backfaceVisibility: 'hidden',
                transform: 'translateZ(0)'
              }}
            >
              <g>
                <circle 
                  cx="50" 
                  cy="50" 
                  r="30" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2"
                  className="globe-pulse"
                />
                <path 
                  d="M20 50 C20 30 80 30 80 50 C80 70 20 70 20 50" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2"
                  className="globe-pulse"
                />
                <path 
                  d="M50 20 L50 80" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2"
                  className="globe-pulse"
                />
                <ellipse 
                  cx="50" 
                  cy="50" 
                  rx="45" 
                  ry="15" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  transform="rotate(-30, 50, 50)"
                  opacity="0.5"
                />
                <circle 
                  cx="50" 
                  cy="50" 
                  r="3" 
                  fill="currentColor" 
                  className="electron"
                />
              </g>
            </svg>
          </div>

          <h1 className={`text-[min(8vw,60px)] font-medium mb-4 sm:mb-6 leading-tight relative z-10 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            R&D Engineer in Medical Technology
          </h1>
          <p className={`text-[min(4vw,24px)] max-w-3xl mx-auto leading-relaxed relative z-10 px-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            Pioneering breakthrough solutions in Medical Technology across Orthopaedics, Oncology, and Dermatology
          </p>
          <button 
            onClick={handleScrollClick}
            className={`mt-8 sm:mt-16 p-4 rounded-full transition-colors cursor-pointer relative z-20 ${isDarkMode ? 'text-white hover:bg-white/10' : 'text-gray-800 hover:bg-gray-100'}`}
            aria-label="Scroll to projects"
          >
            <svg viewBox="0 0 100 100" className="w-10 h-10">
              <path 
                d="M20 40 L50 70 L80 40" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </section>

        {/* Logo Ticker */}
        <div className="-mt-32">
          <LogoTicker />
        </div>

        {/* Projects Section */}
        <section className="py-8 mt-[100px]" id="projects">
          <div className="flex justify-between items-center mb-12">
            <h2 className={`text-[60px] font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Featured Projects</h2>
            <a href="#" className={`underline text-xl ${isDarkMode ? 'text-white' : 'text-black'}`}>View all projects</a>
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            <div className={`group space-y-4`}>
              <div className="relative w-full aspect-[4/3] overflow-hidden rounded-lg">
                <img 
                  src="/images/Cancerdetect.jpeg" 
                  alt="Cancer detection device" 
                  className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="space-y-4">
                <div className={`flex items-center space-x-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  <span>Research</span>
                  <span>•</span>
                  <span>Medical Technology</span>
                </div>
                <h3 className={`text-2xl font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                  Breakthrough Cancer Detection
                </h3>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Leading development of innovative skin cancer detection device, achieving 95% reduction in measurement time.
                </p>
              </div>
            </div>
            <div className={`group space-y-4`}>
              <div className="relative w-full aspect-[4/3] overflow-hidden rounded-lg">
                <img 
                  src="/images/HipSim.PNG" 
                  alt="Hip simulation system" 
                  className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="space-y-4">
                <div className={`flex items-center space-x-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  <span>Innovation</span>
                  <span>•</span>
                  <span>Orthopaedics</span>
                </div>
                <h3 className={`text-2xl font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                  Advanced Hip Simulation
                </h3>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Pioneered novel pre-clinical testing methodology for natural hip simulation.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Blog Section */}
        <section className="py-8" id="blog">
          <div className="flex justify-between items-center mb-12">
            <h2 className={`text-[60px] font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>Latest Posts</h2>
            <Link to="/blog" className={`underline text-xl ${isDarkMode ? 'text-white' : 'text-black'}`}>View all posts</Link>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {blogPosts.slice(0, 3).map((post, index) => (
              <div key={post.slug} className={`group space-y-4`}>
                <div className="relative w-full aspect-[4/3] overflow-hidden rounded-lg">
                  <img 
                    src={post.image} 
                    alt={post.title} 
                    className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="space-y-4">
                  <div className={`flex items-center space-x-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    <span>{post.tags[0]}</span>
                    <span>•</span>
                    <span>{post.date}</span>
                  </div>
                  <h3 className={`text-2xl font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                    {post.title}
                  </h3>
                  <Link 
                    to={`/blog/${post.slug}`} 
                    className={`inline-block hover:underline ${isDarkMode ? 'text-white' : 'text-black'}`}
                  >
                    Read →
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Contact Section */}
        <section className="text-center pb-16 space-y-6">
          <h2 className={`text-3xl font-medium ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            Set up a meeting or request my services today!
          </h2>
          <a 
            href="mailto:mudit.d@hotmail.com" 
            className={`inline-block px-6 py-3 text-lg font-medium rounded-lg transition-colors shadow-lg ${
              isDarkMode ? 'bg-white text-gray-900 hover:bg-gray-200' : 'bg-gray-900 text-white hover:bg-gray-800'
            }`}
          >
            Contact Now
          </a>
        </section>
      </main>
    );
  };

  return (
    <div className="min-h-screen">
      <MainContent />
    </div>
  );
};

export default Portfolio;
