import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { blogPosts } from '../data/blogPosts';
import { useTheme } from '../context/ThemeContext';

const BlogPage = () => {
  const { isDarkMode } = useTheme();
  const [selectedTags, setSelectedTags] = useState([]);
  
  // Get all unique tags
  const allTags = [...new Set(blogPosts.flatMap(post => post.tags))].sort();
  
  // Filter posts based on selected tags
  const filteredPosts = selectedTags.length === 0
    ? blogPosts
    : blogPosts.filter(post => 
        selectedTags.every(tag => post.tags.includes(tag))
      );

  const toggleTag = (tag) => {
    setSelectedTags(prev =>
      prev.includes(tag)
        ? prev.filter(t => t !== tag)
        : [...prev, tag]
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto px-4 pt-32 pb-16 relative z-10">
      <div className="max-w-7xl mx-auto">
        <div className="mb-12">
          <h1 className={`text-[60px] font-medium mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Blog
          </h1>
          <p className={`text-xl ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            Exploring breakthroughs in Medical Technology and Healthcare Innovation & Personal Growth
          </p>
        </div>

        <div className="flex flex-col md:flex-row gap-8">
          {/* Tags Filter Sidebar */}
          <div className="md:w-64 flex-shrink-0">
            <div className={`backdrop-blur-xl rounded-xl p-6 ${
              isDarkMode ? 'bg-zinc-900/5 text-white' : 'bg-white/5 text-gray-800'
            }`}>
              <h2 className="text-xl font-semibold mb-4">Filter by Topic</h2>
              <div className="space-y-2">
                {allTags.map(tag => (
                  <button
                    key={tag}
                    onClick={() => toggleTag(tag)}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 backdrop-blur-md border ${
                      selectedTags.includes(tag)
                        ? isDarkMode
                          ? 'bg-white/90 text-black border-white/50'
                          : 'bg-gray-900/90 text-white border-gray-800'
                        : isDarkMode
                        ? 'bg-gray-800/50 text-white hover:bg-gray-700/60 border-gray-700/50'
                        : 'bg-white/50 text-gray-800 hover:bg-white/60 border-gray-200/50'
                    }`}
                  >
                    {tag}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Blog Posts Grid */}
          <div className="flex-1">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
              {filteredPosts.map((post) => (
                <div
                  key={post.slug}
                  className={`group relative overflow-hidden rounded-2xl backdrop-blur-md shadow-lg transition-all duration-300 hover:shadow-xl border ${
                    isDarkMode
                      ? 'bg-gray-800/30 border-gray-700/30 hover:bg-gray-800/40'
                      : 'bg-white/30 border-gray-200/30 hover:bg-white/40'
                  }`}
                >
                  <div className="h-48 overflow-hidden">
                    <img
                      src={post.image}
                      alt={post.title}
                      className="w-full h-full object-cover object-center transform group-hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-wrap gap-2 mb-4">
                      {post.tags.map((tag) => (
                        <span
                          key={tag}
                          className={`px-3 py-1 text-xs font-medium rounded-full backdrop-blur-sm ${
                            isDarkMode
                              ? 'bg-gray-700/30 text-gray-200 border border-gray-600/30'
                              : 'bg-gray-100/30 text-gray-800 border border-gray-200/30'
                          }`}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                    <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {post.title}
                    </h3>
                    <p className={`mb-4 text-sm line-clamp-3 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                      {post.excerpt}
                    </p>
                    <div className="flex items-center justify-between">
                      <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        {post.date} · {post.readTime} min read
                      </span>
                      <Link
                        to={`/blog/${post.slug}`}
                        className={`inline-flex items-center text-sm font-medium transition-colors duration-200 ${
                          isDarkMode
                            ? 'text-blue-400 hover:text-blue-300'
                            : 'text-blue-600 hover:text-blue-500'
                        }`}
                      >
                        Read →
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
