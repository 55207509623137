import React from 'react';
import { useTheme } from '../context/ThemeContext';

const LogoTicker = () => {
  const { isDarkMode } = useTheme();
  
  const logos = [
    {
      name: 'GSK',
      lightSrc: '/images/Logo scroll/Light mode/GSK_logo_2022.svg.png',
      darkSrc: '/images/Logo scroll/Dark mode/GSK_logo_2022.svg.png',
      alt: 'GSK',
      height: '40px'
    },
    {
      name: 'DePuy Synthes',
      lightSrc: '/images/Logo scroll/Light mode/DPS_Logo_NoSig_RGB.webp',
      darkSrc: '/images/Logo scroll/Dark mode/e03b7089-efb6-4c4a-8bb8-4dfa387f4ff0.png',
      alt: 'DePuy Synthes',
      height: '35px'
    },
    {
      name: 'University of Exeter',
      lightSrc: '/images/Logo scroll/Light mode/UNEXE_Logo.png',
      darkSrc: '/images/Logo scroll/Dark mode/UNEXE_Logo.png',
      alt: 'University of Exeter',
      height: '45px'
    },
    {
      name: 'IMechE',
      lightSrc: '/images/Logo scroll/Light mode/imeche-logo-th.png',
      darkSrc: '/images/Logo scroll/Dark mode/imeche-logo-th.png',
      alt: 'IMechE',
      height: '55px'
    },
    {
      name: 'Papworth Trust',
      lightSrc: '/images/Logo scroll/Light mode/papworth-trust-logo.png',
      darkSrc: '/images/Logo scroll/Dark mode/papworth-trust-logo.png',
      alt: 'Papworth Trust',
      height: '40px'
    },
    {
      name: 'Queen Mary University of London',
      lightSrc: '/images/Logo scroll/Light mode/png-clipart-queen-mary-university-of-london-research-college-student-text-people.png',
      darkSrc: '/images/Logo scroll/Dark mode/qmul_logo_white.gif',
      alt: 'Queen Mary University of London',
      height: '45px'
    }
  ];

  // Duplicate logos for seamless infinite scroll
  const duplicatedLogos = [...logos, ...logos];

  return (
    <div className="w-full overflow-hidden py-4 sm:py-8">
      <div className="relative flex animate-scroll">
        {duplicatedLogos.map((logo, index) => (
          <div
            key={index}
            className="flex-shrink-0 px-8 grayscale hover:grayscale-0 transition-all duration-300"
          >
            <img
              src={isDarkMode ? logo.darkSrc : logo.lightSrc}
              alt={logo.alt}
              className="h-[min(8vw,55px)] w-auto object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoTicker;
